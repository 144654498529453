import React, { useEffect, useState } from 'react'
import ReactECharts from 'echarts-for-react'
import styles from './style.module.scss'
import { t } from '@lingui/macro'
import { IconButton } from '../../IconButton/IconButton'
import { SVG } from '@/assets/svg'
import { InteractiveModal } from '@/ui/kit'
import { SettingForm } from './SettingForm/SettingForm'
import { WidgetRowServerData } from '../../wrapper/settings'
import { isEmpty } from 'lodash-es'
import { EChartsEmpty } from '../../ECharts'

type CategoryState = {
  name: string
  value: number
  active: boolean
}

type ProfitChartProps = {
  serverData: WidgetRowServerData[] | null
}

export const ProfitByReason: React.FC<ProfitChartProps> = ({ serverData }) => {
  const [categoryState, setCategoryState] = useState<CategoryState[]>([])

  function sortArray(arr: CategoryState[]) {
    return arr.sort((a, b) => {
      return a.value - b.value
    })
  }

  const loadCategoriesFromLocalStorage = (serverData: WidgetRowServerData[] | null): CategoryState[] => {
    const storedCategories = localStorage.getItem('categories')

    if (storedCategories) {
      const parsedCategories = JSON.parse(storedCategories)

      if (
        Array.isArray(parsedCategories) &&
        parsedCategories.length > 0 &&
        serverData &&
        Object.keys(serverData).length === parsedCategories.length
      ) {
        return parsedCategories
      }
    }

    if (serverData && Object.keys(serverData).length > 0) {
      const arr = Object.keys(serverData).map((key: string) => {
        const item = serverData[parseInt(key)]

        // Парсинг значения и защита от некорректных значений (например, NaN)
        const parsedValue = parseFloat(item.value || '0')
        const value = isNaN(parsedValue) ? 0 : parsedValue

        return {
          name: item.name || 'Unknown',
          value: value,
          active: true,
        }
      })

      return sortArray(arr)
    }

    return []
  }

  // Загружаем данные из serverData или localStorage при первом рендере
  useEffect(() => {
    if (serverData) {
      const initialCategories = loadCategoriesFromLocalStorage(serverData)
      setCategoryState(initialCategories)
    }
  }, [serverData])

  // Сохраняем категории в localStorage при изменении categoryState
  useEffect(() => {
    if (categoryState.length > 0) {
      localStorage.setItem('categories', JSON.stringify(categoryState))
    }
  }, [categoryState])

  // Отбираем только активные категории
  const activeCategories = categoryState.filter(category => category.active)
  const currentCategories = sortArray(activeCategories).map(item => item.name)
  const values = activeCategories.map(item => item.value)

  const [showSettings, setShowSettings] = useState(false)

  const chartHeight = Math.max(280, activeCategories.length * 40)

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      backgroundColor: '#1C1A24CC', // Прозрачный фон для tooltip
      borderColor: '#67657366', // Полупрозрачная рамка
      borderRadius: 10,
      textStyle: {
        color: '#FFFFFF', // Цвет текста
      },
      formatter: (params: any) => {
        const date = params[0].axisValueLabel
        const value = params[0].value
        return `${date}: ${value} $`
      },
    },
    xAxis: {
      type: 'value',
      splitLine: {
        show: false,
      },
      axisLabel: {
        formatter: '{value} $',
        margin: 30,
      },
    },
    yAxis: {
      type: 'category',
      data: currentCategories, // Используем преобразованные данные для категорий
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true, // Показать линии сетки
        lineStyle: {
          type: 'dashed', // Тип линии - пунктирный
          color: '#FFFFFF26', // Цвет пунктирной линии
        },
      },
      axisLabel: {
        formatter: (value: string, index: number) => {
          let formattedValue = new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'USD',
          }).format(values[index]) // Форматируем значения как валюту

          if (values[index] > 0) {
            formattedValue = `+${formattedValue}`
          }

          const styleName = values[index] > 0 ? 'positive' : 'negative'

          return `{category|${value}}  {${styleName}|${formattedValue}}`
        },
        align: 'left', // Выравнивание текста по левому краю
        padding: [0, 0, 0, -80], // Подвинуть текст ближе к краю (можно регулировать)
        color: '#676573', // Цвет текста
        fontSize: 14,
        rich: {
          category: {
            align: 'left',
            padding: [0, 0, 0, -20],
            width: 150, // Ширина для названия категории
            fontFamily: 'Arial, sans-serif',
            fontSize: 14,
            color: '#676573',
          },
          positive: {
            align: 'right',
            width: 100, // Ширина для значения
            padding: [0, 0, 0, 305], // Отступы слева, чтобы прижать к левому краю
            fontFamily: 'Arial, sans-serif',
            fontSize: 14,
            fontWeight: 'bold',
            color: '#1ACC81',
          },
          negative: {
            align: 'right',
            width: 100, // Ширина для значения
            padding: [0, 0, 0, 305], // Отступы слева, чтобы прижать к левому краю
            fontFamily: 'Arial, sans-serif',
            fontSize: 14,
            fontWeight: 'bold',
            color: 'white',
          },
        },
      },
    },
    series: [
      {
        name: 'Profit',
        type: 'bar',
        data: values, // Используем преобразованные данные для значений
        itemStyle: {
          color: (params: any) => (params.value >= 0 ? '#1ACC81' : '#F97052'),
          borderRadius: [2, 2, 2, 2],
        },
        barWidth: '19px', // Уменьшаем ширину столбцов
        barGap: '10px',
        barCategoryGap: '10px', // Расстояние между столбцами в процентах (можно регулировать)
        background: 'blue',
        label: {
          show: false,
        },
      },
    ],
    grid: {
      top: '0px', // Уменьшаем верхний отступ
      left: '-458px',
      right: '95px', // Делаем отступ больше, чтобы поместились метки
      bottom: '0px', // Уменьшаем нижний отступ
      containLabel: true,
    },
    backgroundColor: '#12101a', // Цвет фона графика
  }

  function openSettings() {
    setShowSettings(true)
  }

  function closeSettings() {
    setShowSettings(false)
  }

  function onToggleCategory(name: string) {
    setCategoryState(prevState =>
      prevState.map(category => (category.name === name ? { ...category, active: !category.active } : category))
    )
  }

  if (isEmpty(serverData)) {
    return <EChartsEmpty className={styles.profitContainer} />
  }

  return (
    <>
      <div className={styles.profitContainer}>
        <div className={styles.header}>
          <div className={styles.chartTitle}>
            {t({ id: 'widgets.profitForReason.title', comment: 'Profit for Reason' })}
          </div>
          <IconButton className={styles.iconButton} leftIcon={<SVG.OtherIcons.Settings />} onClick={openSettings} />
        </div>
        <div className={styles.chartWrapper}>
          <ReactECharts
            option={options}
            notMerge={true}
            className={styles.chartBackground}
            style={{ height: chartHeight + 'px' }}
          />
        </div>
      </div>
      <InteractiveModal className={styles.modal} isOpen={showSettings} onClose={closeSettings}>
        <SettingForm onClose={closeSettings} categories={categoryState} onToggleCategory={onToggleCategory} />
      </InteractiveModal>
    </>
  )
}
