import { t } from '@lingui/macro'
import { useMemo } from 'react'
import article from '@/assets/jpg/article.jpg'
import video_0 from '@/assets/jpg/content/proscalping_0.jpg'
import video_1 from '@/assets/jpg/content/proscalping_1.jpg'
import video_2 from '@/assets/jpg/content/proscalping_2.jpg'
import { ACHIEVEMENTS_CONTENT, PARTNER_TRADING_VOLUME } from '@/core/config/partner'
import { EPartnerAchievements } from '@/redux/partner/partner.types'
import { getTtLinksByLocale } from '@/router/links'
import { getPartnerTranslations } from '@/translations/partner'
import { TAchievementSouce } from '@/ui/molecules/Achievements/Achievements'
import { PlayBtn } from '@/ui/molecules/VideoPlayer/PlayBtn'
import { formatNumber, useAppSelector } from '@/utils'
import { TArticle } from '../../Articles/Articles.types'
import { TPartnerAchievementTranslationRecord } from '../PartnerRightBar.types'
import { useLanguageContext } from '@/providers/LanguageProvider'

export const useAchievementsData = (
  showPlayer: Function
): {
  achievementsSource: TAchievementSouce[]
  differentSource: TArticle[]
  videoSource: TArticle[]
  studySource: TArticle[]
} => {
  const { locale } = useLanguageContext()
  const { achievements, achievementPending, tradingVolume } = useAppSelector(state => state.partner)
  const translations = useMemo(() => {
    return getPartnerTranslations()
  }, [])

  const achievementsSource = useMemo<TAchievementSouce[]>(() => {
    return achievements.map(achievement => {
      const type = achievement.achievementType
      const content = ACHIEVEMENTS_CONTENT[type]
      const translation: TPartnerAchievementTranslationRecord = translations.achievementsVocabulary[type]
      const tradingVolumeShown =
        tradingVolume && tradingVolume > PARTNER_TRADING_VOLUME ? PARTNER_TRADING_VOLUME : tradingVolume
      return {
        id: type,
        icon: content.icon,
        link: content.link,
        text: {
          ...translation,
          description: (
            <>
              {translation.description}
              {type === EPartnerAchievements.tradingVolume10K && (
                <>
                  <br />
                  {formatNumber(tradingVolumeShown, {
                    maximumFractionDigits: 2,
                  })}{' '}
                  / {formatNumber(PARTNER_TRADING_VOLUME)}
                </>
              )}
            </>
          ),
        },
        unlocked: achievement.unlocked,
        pending: achievementPending?.achievementType === achievement.achievementType,
      }
    })
  }, [achievements, translations, achievementPending, tradingVolume])

  const differentSource = useMemo<TArticle[]>(() => {
    return [
      {
        date: '09.11.23',
        tags: ['#terminal'],
        heading: 'Файл конфигурации',
        text: 'Файл конфигурации терминала Tider.Trade',
        linkText: t({ id: 'core.download' }),
        image: article,
        callback: () => {
          window.open(getTtLinksByLocale('tt_cdn_proscalping', locale))
        },
      },
    ]
  }, [])

  const videoSource = useMemo<TArticle[]>(() => {
    const volumeAchievement = achievementsSource.find(acievementItem => {
      return acievementItem.id === EPartnerAchievements.tradingVolume10K
    })

    return [
      {
        date: '06.11.23',
        tags: ['#trading'],
        heading: 'Закрытый разбор',
        text: 'Большой разбор сделок за рабочий день',
        image: video_0,
        customLink: ({ onClick }) => {
          return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
        },
        linkToImage: true,
        callback: () => {
          showPlayer('Fpd7FT6QsLA')
        },
      },
      {
        date: '06.02.24',
        tags: ['#trading'],
        heading: 'СКАЛЬПИНГ ПО СТАКАНУ',
        text: 'Скальпинг по стакану',
        image: video_1,
        customLink: ({ onClick }) => {
          return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
        },
        linkToImage: true,
        callback: () => {
          showPlayer('Tnfc4H0k9co')
        },
        disabled: !volumeAchievement?.unlocked,
        tradingVolume,
      },
      {
        date: '18.09.24',
        tags: ['#trading'],
        heading: 'ОДИН ДЕНЬ ЖИВОЙ ТОРГОВЛИ',
        text: 'Торговый день',
        image: video_2,
        customLink: ({ onClick }) => {
          return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
        },
        linkToImage: true,
        callback: () => {
          showPlayer('xpG80DzMTDE')
        },
        disabled: !volumeAchievement?.unlocked,
        tradingVolume,
      },
    ]
  }, [tradingVolume, showPlayer, achievementsSource])

  return {
    achievementsSource,
    differentSource,
    videoSource,
    studySource: [],
  }
}
